'use client';

import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';

import type { ISubscription } from '@/shared/firebase';
import { useGetWorkspaceGeneralSetting } from '@/shared/firebase/client/hooks';
import { useGetPlans } from '@/shared/firebase/client/hooks/plan.hooks';
import { useGetSubscriptions } from '@/shared/firebase/client/hooks/subscription.hooks';
import type {
  IBillingScheme,
  IPlan,
} from '@/shared/firebase/models/stripe-payment.interface';
import { useAuth } from '@/shared/hooks/useAuth';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';

import ContactSalesTeamRender from './plan/plan-category/components/ContactSalesTeamRender';
import PlansSkeleton from './plan/plan-category/components/PlansSkeleton';
import CustomPlan from './plan/plan-category/CustomPlanPreview';
import FreePlan from './plan/plan-category/FreePlan';
import IntervalSelector from './plan/plan-category/IntervalSelector';
import StandardPlan from './plan/plan-category/StandardPlan';
import UserCustomPlan from './plan/plan-category/UserCustomPlan';

type PlansProps = {
  isLanding?: boolean;
  isPricingPage?: boolean;
  isPriceOverviewPage?: boolean;
};
export type ISelectInterval = 'month' | 'year';
export default function Plans({
  isLanding,
  isPricingPage,
  isPriceOverviewPage,
}: PlansProps) {
  const [interval, setInterval] = useState<ISelectInterval>('month');
  const [billingScheme, setBillingScheme] =
    useState<IBillingScheme>('per_unit');
  const [workspaceSubscription, setWorkspaceSubscription] =
    useState<ISubscription>();
  const [intervalPlans, setIntervalPlans] = useState<IPlan[]>([]);
  const [totalCols, setTotalCols] = useState(2);

  const { user: authUser } = useAuth();
  const { currentUser, state: currentUserState } = useCurrentUser();

  const { data: plans, state: plansState } = useGetPlans({
    key: 'all-plan-list',
  });

  const { data: freePlanAttributes, state: freePlanAttributesState } =
    useGetWorkspaceGeneralSetting({ key: 'general-settings-free-plan' });

  const { data: subscriptions, state: subscriptionsState } =
    useGetSubscriptions({
      workspaceId: currentUser?.defaultWorkspaceId,
      key: 'plan-list-subscription',
    });

  useEffect(() => {
    if (subscriptionsState.isSuccess && currentUserState?.isSuccess) {
      const currentWorkspaceSubscription = subscriptions.find(
        (subscription) =>
          subscription.workspace.id === currentUser?.defaultWorkspaceId
      );
      setWorkspaceSubscription(currentWorkspaceSubscription);
    }
  }, [subscriptionsState.isSuccess, currentUserState?.isSuccess]);

  const customPlan = useMemo(
    (): IPlan | null =>
      intervalPlans.find(
        (plan) =>
          plan.prices.findIndex((price) => price.userId === currentUser?.id) !==
          -1
      ) ?? null,
    [plansState.isSuccess, intervalPlans]
  );

  const standardPlans = useMemo(
    () => intervalPlans.filter((plan) => !plan.custom),
    [plansState.isSuccess, intervalPlans]
  );

  useEffect(() => {
    if (plansState.isSuccess) {
      // 2 is for free and custom plans
      let total = standardPlans.length + 2;
      if (customPlan) {
        total += 1;
      }

      setTotalCols(total);
    }
  }, [plansState.isSuccess, intervalPlans]);

  const onSetInterval = (planInterval: ISelectInterval) => {
    setInterval(planInterval);
  };

  useEffect(() => {
    const perIntervalPlans = plans.filter(
      (plan) =>
        plan.prices.findIndex(
          (price) => price.recurring?.interval === interval
        ) !== -1
    );
    setIntervalPlans([...perIntervalPlans]);
  }, [interval, plansState.isSuccess]);

  const handleBillingSchemeChange = (e: IBillingScheme) => {
    setBillingScheme(e);
  };

  return (
    <div className="flex w-full flex-col gap-8">
      <IntervalSelector
        interval={interval}
        onSetInterval={onSetInterval}
        isPricePage={isPricingPage}
        isLanding={isLanding}
      />
      {(plansState.isLoading ||
        freePlanAttributesState.isLoading ||
        currentUserState?.isLoading ||
        subscriptionsState.isLoading) && <PlansSkeleton />}
      {((plansState.isSuccess && !authUser) ||
        (authUser &&
          plansState.isSuccess &&
          freePlanAttributesState.isSuccess &&
          currentUserState?.isSuccess &&
          subscriptionsState.isSuccess)) && (
        <div
          className={clsx('grid w-full grid-cols-1', {
            'sm:grid-cols-2 2xl:grid-cols-4 lg:grid-cols-3 gap-4':
              totalCols >= 4,
            'sm:grid-cols-2 lg:grid-cols-3 gap-10': totalCols === 3,
            'sm:grid-cols-2 gap-4': totalCols === 2,
          })}
        >
          {freePlanAttributes?.attributes && (
            <FreePlan
              attributes={freePlanAttributes.attributes}
              planSubscription={workspaceSubscription !== null}
              isLanding={isLanding}
              isPricePage={isPricingPage}
              isPriceOverviewPage={isPriceOverviewPage}
            />
          )}
          <>
            {standardPlans.map((plan, index) => (
              <StandardPlan
                key={index}
                plan={plan}
                interval={interval}
                workspaceSubscription={workspaceSubscription}
                isLanding={isLanding}
                isPricePage={isPricingPage}
                isPopular={standardPlans[0]?.id === plan.id}
                isPriceOverviewPage={isPriceOverviewPage}
              />
            ))}

            {customPlan ? (
              <UserCustomPlan
                interval={interval}
                plan={customPlan}
                workspaceSubscription={workspaceSubscription}
                billingScheme={billingScheme}
                onBillingSchemeChange={handleBillingSchemeChange}
                isPricePage={isPricingPage}
                isLanding={isLanding}
                isPriceOverviewPage={isPriceOverviewPage}
              />
            ) : null}
          </>
          <ContactSalesTeamRender>
            {({ openCustomRequestModal }) => (
              <CustomPlan
                isLanding={isLanding}
                isPricePage={isPricingPage}
                isPriceOverviewPage={isPriceOverviewPage}
                attributes={{
                  allowSpeechToText: true,
                  allowSSO: true,
                  customizableBrand: true,
                  integration: {
                    github: true,
                    gmail: true,
                    saleForce: true,
                    slack: true,
                    upWork: true,
                    zoom: true,
                  },
                  video: {
                    analytics: 'advanced',
                    duration: 'inf',
                    limit: 'inf',
                    quality: '4k',
                  },
                }}
                openContactModal={openCustomRequestModal}
              />
            )}
          </ContactSalesTeamRender>
        </div>
      )}
    </div>
  );
}
