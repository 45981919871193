import { Button } from '@canvaza/serval-ui';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import React from 'react';

import type { IPlanAttributes } from '@/shared/firebase/models/stripe-payment.interface';

import Attributes from './attributes';
import PlanContainer from './components/PlanContainer';
import PlanDescription from './components/PlanDescription';
import PlanTitle from './components/PlanTitle';

type FreePlanProps = {
  attributes: IPlanAttributes;
  planSubscription: boolean;
  isLanding?: boolean;
  isPricePage?: boolean;
  isPriceOverviewPage?: boolean;
};

export default function FreePlan({
  attributes,
  planSubscription,
  isLanding,
  isPricePage,
  isPriceOverviewPage,
}: FreePlanProps) {
  const router = useRouter();
  const gotToSignUp = () => {
    router.push('/signup');
  };
  const priceDescription = () => {
    router.push('/plan-overview');
  };
  return (
    <PlanContainer isPricingPage={isPricePage} isLanding={isLanding}>
      <div className="flex h-4/5 flex-col">
        <div className="flex flex-col gap-3">
          <PlanTitle
            name="Starter"
            isPricePage={isPricePage}
            isLanding={isLanding}
          />
          <span
            className={clsx('p-0.5 text-3xl font-bold', {
              'text-dark-lighter': isPricePage,
              'text-dark-700': isLanding,
              'text-dark-700 dark:text-dark-light': !isPricePage && !isLanding,
            })}
          >
            Free trial
          </span>
          <PlanDescription
            description="Recording video and basic analytics."
            isPricePage={isPricePage}
            isLanding={isLanding}
          />
          {isLanding ? (
            <Button
              fullWidth
              size="small"
              variant="outlined"
              onClick={gotToSignUp}
            >
              <span
                className={clsx('text-primary dark:text-primary-light', {
                  'text-primary-light': isPricePage,
                  'text-primary': !isPricePage,
                })}
              >
                Sign Up
              </span>
            </Button>
          ) : (
            <Button fullWidth size="large-x">
              <span className="text-primary dark:text-primary-light">
                Free Trial
              </span>
            </Button>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <span
            className={clsx(
              'text-center text-3xl font-bold text-primary dark:text-primary-light',
              {
                'text-primary-light': isPricePage,
                'text-primary': !isPricePage,
              }
            )}
          >
            {!planSubscription && (
              <span
                className={clsx('font-normal', {
                  'text-dark-lighter': isPricePage,
                  'text-dark-700': isLanding,
                  'text-dark-700 dark:text-dark-light':
                    !isPricePage && !isLanding,
                })}
              >
                Your current plan
              </span>
            )}
          </span>
          <p
            className={clsx('font-medium', {
              'text-dark-lighter': isPricePage,
              'text-dark-700': isLanding,
              'text-dark-700 dark:text-dark-light': !isPricePage && !isLanding,
            })}
          >
            Features you will have
          </p>
          <Attributes
            attributes={attributes}
            isPricePage={isPricePage}
            isLanding={isLanding}
          />
        </div>
      </div>
      {!isLanding && !isPriceOverviewPage && (
        <div className="flex w-full items-center justify-center">
          <Button onClick={priceDescription}>Detail description</Button>
        </div>
      )}
    </PlanContainer>
  );
}
